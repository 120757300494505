<template>
  <div class="pb-5" ref="container">
    <v-card class="teros-elevation">
      <v-card-title class="px-5 text-uppercase v-title-cadastro">
        {{ title }}
      </v-card-title>
      <v-card-text class="px-5 v-form-cadastro">
        <v-row>
          <v-col :cols="12">
            <v-alert v-model="errorNotification" color="error" text outlined>
              <v-icon color="error" left>mdi-alert-circle-outline</v-icon>
              {{ errorMessage }}
            </v-alert>
            <tipo-usuario-form
              v-model="usuario.tipo_usuario"
              class="mb-4"
              :sending="isSend"
            ></tipo-usuario-form>
          </v-col>
          <v-col>
            <admin-form
              v-if="formDisplay.ADMINISTRADOR"
              :isNewUser="isNewUser"
              :opts="opts"
              :sending.sync="isSend"
              :user="usuario"
              @error="onError"
              @save="doSave"
            ></admin-form>
            <consultor-form
              v-else-if="formDisplay.CONSULTOR"
              :isNewUser="isNewUser"
              :opts="opts"
              :sending.sync="isSend"
              :user="usuario"
              @error="onError"
              @save="doSave"
            ></consultor-form>
            <cliente-admin-form
              v-else-if="formDisplay.CLIENTE_ADMINISTRADOR"
              :isNewUser="isNewUser"
              :opts="opts"
              :sending.sync="isSend"
              :user="usuario"
              @error="onError"
              @save="doSave"
            ></cliente-admin-form>
            <cliente-consultor-form
              v-else-if="formDisplay.CLIENTE_CONSULTOR"
              :isNewUser="isNewUser"
              :opts="opts"
              :sending.sync="isSend"
              :user="usuario"
              @error="onError"
              @save="doSave"
            ></cliente-consultor-form>
            <cliente-gerencial-form
              v-else-if="formDisplay.GERENCIAL_CLIENTE"
              :isNewUser="isNewUser"
              :opts="opts"
              :sending.sync="isSend"
              :user="usuario"
              @error="onError"
              @save="doSave"            
            ></cliente-gerencial-form>
            <cliente-operacional-form
              v-else-if="formDisplay.COLABORADOR"
              :isNewUser="isNewUser"
              :opts="opts"
              :sending.sync="isSend"
              :user="usuario"
              @error="onError"
              @save="doSave"
            ></cliente-operacional-form>
            <template v-else>
              <p>Selecione um tipo de usuário.</p>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { forEach, isEmpty, isObject, sortBy } from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import { UserTypeEnum } from "@/core/enums/user-types.js";

export default {
  beforeRouteEnter: function (to, from, next) {
    next((vm) => {
      const { id: userId } = vm.$store.getters.user || {};
      const { id: paramId } = to.params;
      // Sinto que não deveria ser assim, mas na versão atual é a unica forma de fazer funcionar.
      next(userId == paramId ? { name: 'meu-perfil' } : true);
    });
  },
  components: {
    "admin-form": () => import("./forms/admin.form.vue"),
    "cliente-admin-form": () => import("./forms/cliente.admin.form.vue"),
    "cliente-consultor-form": () => import("./forms/cliente.consultor.form.vue"),
    "cliente-gerencial-form": () => import("./forms/cliente.gerencial.form.vue"),
    "cliente-operacional-form": () => import("./forms/cliente.operacional.form.vue"),
    "consultor-form": () => import("./forms/consultor.form.vue"),
    "tipo-usuario-form": () => import("./forms/tipo-usuario.form.vue"),
  },
  created: async function () {
    try {
      this.isSend = true;
      const requests = [this.doLoadGruposEmpresas(), this.doLoadEmpresas()];

      if (this.isNewUser) {
        this.usuario.senha = this.defaultPassword;
      } else {
        requests.push(this.doLoad());
      }

      await Promise.allSettled(requests);
    } catch (error) {
      this.onError(error);
    } finally {
      this.isSend = false;
    }
  },
  computed: {
    ...mapGetters({
      clientId: "clientId",
      loggedUserCorporacaoId: "getUserCorporacaoId",
      user: "user",
    }),
    defaultPassword: function () {
      return `Teros@${moment().format("YYYY")}`;
    },
    formDisplay: function () {
      const { tipo_usuario } = this.usuario;
      return Object.keys(UserTypeEnum).reduce((acc, key) => ({ ...acc, [key]: tipo_usuario === UserTypeEnum[key] }), {});
    },
    isNewUser: function () {
      return this.$route.meta.mode === "create";
    },
    opts: function () {
      return {
        clientes: this.clientes,
        gruposDeEmpresa: this.gruposDeEmpresa,
      };
    },
    resource: function () {
      return this.apiResource(`/v1/acesso/controleacesso`);
    },
    title: function () {
      return this.isNewUser
        ? "Cadastro de Usuário do Sistema"
        : `Edição do Usuário ${this.usuario.usuario || 'do Sistema'}`;
    },
    userId: function () {
      return this.$route.params.id;
    },
  },
  data: function () {
    const usuario = {
        id: null,
        criado_em: null,
        modificado_em: null,
        deletado_em: null,
        usuario: null,
        nome: null,
        email: null,
        senha: null,
        tipo_usuario: null,
        permissoes: [],
        linkData: null,
        connectionId: null,
        perfilId: null,
        foto: null,
        link_data: null,
        empresaId: null,
        empresaIdList: null,
        consultorId: null,
        funcionarioId: null,
        ativo: true,
        tipo_permissionamento: false,
        corporacao_id: 0,
      };

    return {
      clientes: [],
      errorMessage: '',
      errorNotification: false,
      gruposDeEmpresa: [],
      isSend: false,
      usuario,
    };
  },
  methods: {
    doLoad: async function () {
      if (this.isNewUser) {
        return;
      }

      try {
        this.isSend = true;
        const response = await this.resource.get(this.userId);

        if (response.error) {
          throw response;
        }

        const [{ link_data, ...user }] = response;

        if (isObject(link_data) && !isEmpty(link_data)) {
          forEach(link_data, (data, key) => {
            if (!(key in user)) {
              user[key] = data;
            }
          });
        }

        this.usuario = user;
      } catch (error) {
        this.onError(error);
      } finally {
        this.isSend = false;
      }
    },
    doLoadGruposEmpresas: async function () {
      try {
        this.isSend = true;
        const resource = this.apiResource(`/v1/clientes/${this.clientId}/corporacao`);
        const response = await resource.get();

        if (response.error) {
          throw response;
        }

        const grupos = Array.isArray(response) ? response : [];
        this.gruposDeEmpresa = [{ id: 0, nome: "Nenhum" }].concat(grupos);
      } catch (error) {
        this.onError(error);
      } finally {
        this.isSend = false;
      }
    },
    doSave: async function (body = {}) {
      try {
        this.isSend = true;

        if (this.isNewUser) {
          body.ativo = true;
          body.foto = "/img/noimg.jpg";
          body.perfilId = 0;
        }

        const response = await this.resource.save(body, body.id);

        if (response.error) {
          throw response;
        }

        if (this.isNewUser) {
          this.$router.push({
            name: "edicao-usuario",
            params: { id: response.id },
          });
        } else {
          this.$router.go(0);
        }
      } catch (error) {
        this.onError(error);
      } finally {
        this.isSend = false;
      }
    },
    doLoadEmpresas: async function () {
      try {
        this.isSend = true;
        const resource = this.apiResource(`/v1/clientes`);
        const response = await resource.get();

        if (response.error) {
          throw response;
        }

        this.clientes = sortBy(response, "fantasia");
      } catch (error) {
        this.onError(error);
      } finally {
        this.isSend = false;
      }
    },
    onError: function (error) {
      this.errorMessage = this.errorHandler(error);
      this.errorNotification = true;
      this.$refs.container.scrollIntoView({ behaviour: "smooth", block: "start" });
    },
  },
  watch: {
    clientId: function () {
      this.$router.push({ name: "listagem-usuarios" });
    },
    "usuario.tipo_usuario": function () {
      this.errorNotification = false;
    },
  },
};
</script>
